<template>
  <div class="login-container pb-3">
    <v-row justify="center">
      <v-col xs="12" sm="7" md="5" lg="4" xl="3">
        <v-card class="ma-3 mt-8">
          <v-card-title>
            <div class="login-header">
              <img
                style="width: 100%"
                src="../assets/images/logo_tracklift_full.svg"
                alt="Logo de tracklift" />
            </div>
            <v-layout justify-center mb-2>
              Inscription
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-alert type="info" outlined>
              Si votre entreprise n'est pas encore enregistré sur tracklift veuillez
              <a href="https://ao-solutions.typeform.com/to/EY9Pbnem" class="info--text">suivre ce lien.</a>
            </v-alert>
            <v-alert
              type="success"
              v-if="success"
            >
              Votre inscription est validée. Nous vous avons envoyé un email pour activer votre compte.
            </v-alert>
            <v-form
              v-if="!success"
              ref="form"
              v-model="formValid"
              lazy-validation
              @submit.prevent="onSubmit"
            >
              <v-text-field
                outlined
                v-model="form.email"
                label="Email"
                type="email"
                name="email"
                :rules="rules['email']"
              />
              <v-text-field
                outlined
                v-model="form.firstName"
                label="Prénom"
                type="text"
                name="firstName"
                :rules="rules['firstName']"
              />
              <v-text-field
                outlined
                v-model="form.lastName"
                label="Nom"
                type="text"
                name="lastName"
                :rules="rules['lastName']"
              />

              <v-layout justify-space-between align-center wrap>
                <v-btn
                  text outlined
                  @click="$router.push({ name: 'Login' })"
                >
                  Se connecter
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                >
                  S'enregistrer
                </v-btn>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  mounted () {
    if (this.$store.state.auth.isAuthenticated) {
      this.$router.push({ name: 'Dashboard' })
    }
  },
  data () {
    const apiErrorField = function (apiErrors, path, _ = '') {
      const errors = apiErrors.filter(e => e.propertyPath === path)
      if (errors.length === 0) {
        return true
      }
      return errors[0].title
    }

    const rules = {
      firstName: [
        v => !!v || "Veuillez fournir un prénom",
        _ => apiErrorField(this.apiErrors, 'firstName')
      ],
      lastName: [
        v => !!v || "Veuillez fournir un nom",
        _ => apiErrorField(this.apiErrors, 'lastName')
      ],
      email: [
        v => !!v || "Veuillez fournir un email",
        _ => apiErrorField(this.apiErrors, 'email')
      ]
    }
    return {
      success: false,
      formValid: true,
      apiErrors: [],
      form: {
        email: '',
        firstName: '',
        lastName: ''
      },
      rules,
      loading: false
    }
  },
  watch: {
    'form.email'() { this.resetValidation('email') },
    'form.firstName'() { this.resetValidation('firstName') },
    'form.lastName'() { this.resetValidation('lastName') }
  },
  methods: {
    resetValidation(path) {
      this.apiErrors = this.apiErrors.filter(e => e.propertyPath !== path)
      if (this.apiErrors.length === 0) {
        this.formValid = true
      }
    },
    clearForm() {
      this.form = {
        email: '',
        firstName: '',
        lastName: ''
      }
    },
    onSubmit() {
      this.unauthorizedEmail = false
      this.loading = true
      this.$apitator.post('/auth/register', this.form)
        .then(() => {
          this.loading = false
          this.success = true
          this.clearForm()
        })
        .catch(err => {
          console.error(err)
          console.error(err.response)
          if (err.response && err.response.data.violations) {
            this.apiErrors = err.response.data.violations
            this.$refs.form.validate()
          } else {
            this.$store.commit('alert/add', {
              text: 'Erreur inconue lors de l\'inscription',
              color: 'error'
            })
          }
          this.loading = false
        })
    }
  }
}
</script>

<style src="@/assets/login.css" />
